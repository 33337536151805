import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { Colors } from "./GlobalStyles";

const outTransition = {
	type: "spring",
	damping: 10,
	stiffness: 100,
};

const variants1 = {
	open: { opacity: 1, scale: 1, x: 0 },
	closed: { opacity: 0, scale: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, transition: outTransition },
};

const variants2 = {
	open: { opacity: 1, scale: 1, x: -180, y: 16, transition: { delay: 0.01 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants3 = {
	open: { opacity: 1, scale: 1, x: -74, y: -70, transition: { delay: 0.02 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants4 = {
	open: { opacity: 1, scale: 1, x: -28, y: -124, transition: { delay: 0.03 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants5 = {
	open: { opacity: 1, scale: 1, x: 90, y: -124, transition: { delay: 0.04 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants6 = {
	open: { opacity: 1, scale: 1, x: -244, y: -38, transition: { delay: 0.05 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants7 = {
	open: { opacity: 1, scale: 1, x: 50, y: -70, transition: { delay: 0.04 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants8 = {
	open: { opacity: 1, scale: 1, x: 182, y: -70, transition: { delay: 0.05 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants9 = {
	open: { opacity: 1, scale: 1, x: -292, y: 16, transition: { delay: 0.06 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants10 = {
	open: { opacity: 1, scale: 1, x: -258, y: 70, transition: { delay: 0.07 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants11 = {
	open: { opacity: 1, scale: 1, x: -144, y: 70, transition: { delay: 0.01 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants12 = {
	open: { opacity: 1, scale: 1, x: -204, y: 124, transition: { delay: 0.02 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants13 = {
	open: { opacity: 1, scale: 1, x: 40, y: 70, transition: { delay: 0.03 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants14 = {
	open: { opacity: 1, scale: 1, x: 195, y: 70, transition: { delay: 0.04 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants15 = {
	open: { opacity: 1, scale: 1, x: 130, y: 124, transition: { delay: 0.05 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants16 = {
	open: { opacity: 1, scale: 1, x: -356, y: -38, transition: { delay: 0.06 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

const variants17 = {
	open: { opacity: 1, scale: 1, x: -92, y: 124, transition: { delay: 0.07 } },
	closed: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
	initial: { opacity: 0, scale: 0, x: 0, y: 0, transition: outTransition },
};

export const RoleTags = props => {
	return (
		<Tags>
			<span>
				<Tag
					color={Colors.yellow}
					shade={Colors.yellowShade}
					className="tag title"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants1}
                    style={{
                        fontWeight: 500,
                    }}
				>
					Design Engineer
				</Tag>
			</span>

			<span>
				<Tag
					color={Colors.purple}
					shade={Colors.purpleShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants3}
				>
					Visual Design
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.purple}
					shade={Colors.purpleShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants4}
				>
					Mockups
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.purple}
					shade={Colors.purpleShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants5}
				>
					Style Guides
				</Tag>
			</span>

			<span>
				<Tag
					color={Colors.purple}
					shade={Colors.purpleShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants7}
				>
					Prototypes
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.purple}
					shade={Colors.purpleShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants8}
				>
					Front-end Code
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants9}
				>
					CSS
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants10}
				>
					HTML
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants11}
				>
					Javascript
				</Tag>
			</span>
			<span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants12}
				>
					Swift
				</Tag>
			</span>
            <span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants17}
				>
					Git
				</Tag>
			</span>
            
            <span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants2}
				>
					React
				</Tag>
			</span>
            <span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants6}
				>
					SQL
				</Tag>
			</span>
            <span>
				<Tag
					color={Colors.blue}
					shade={Colors.blueShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants16}
				>
					Next.js
				</Tag>
			</span>

			<span>
				<Tag
					color={Colors.green}
					shade={Colors.greenShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants13}
				>
					Interface Principles
				</Tag>
			</span>

			<span>
				<Tag
					color={Colors.green}
					shade={Colors.greenShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants14}
				>
					Internal Tools
				</Tag>
			</span>

			<span>
				<Tag
					color={Colors.green}
					shade={Colors.greenShade}
					className="tag"
					animate={props.open ? "open" : "closed"}
					initial="initial"
					variants={variants15}
				>
					Screen Flows
				</Tag>
			</span>
		</Tags>
	);
};

const Tags = styled(motion.span)`
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 2;
    
	> span {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);
	}
`;

const Tag = styled(motion.span)`
	display: block;
	background-color: ${props => props.color};
	color: ${props => props.shade};
	padding: 0.5em 0.75em;
	border-radius: 10px;
	white-space: nowrap;
	text-align: center;
	min-width: 100px;
    font-weight: 600;
	&.title {
		padding: 0.5em 1em;
		font-size: 0.9em;
        border-radius: 10px;
	}
`;
