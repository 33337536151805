import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Switch, Route, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import { Seal } from "./Seal";
import { FigureAbletonApp } from "./FigureAbletonApp";
import { FigureLearningSynths } from "./FigureLearningSynths";
import { Page } from "./Page";
import { Colors } from "./GlobalStyles";
import { RoleTags } from "./Tags";
import { TagsOverlay } from "./TagsOverlay";
import { CaseStudy } from "./CaseStudy";

export const App = () => {
	const location = useLocation();
	const [tagsOpen, setTagsOpen] = React.useState(false);

	return (
		<Wrap>
			<Container>
				<Intro>
					<h1>
						Hello
						<br />
						Anthropic!
					</h1>
					<p>
						I’m Justin. A{" "}
						<YellowLink
							href="#show-design-engineer-info"
							onClick={e => {
								setTagsOpen(!tagsOpen);
								e.preventDefault();
							}}
						>
							<span style={{ color: "#ffc25e" }}>Design Engineer</span>
							<RoleTags open={tagsOpen} />
						</YellowLink>{" "}
						who’s currently exploring AI-powered interactive storytelling at{" "}
						<PurpleLink href="https://tome.app" target="_blank">
							Tome
							<svg width="16" height="16" viewBox="0 0 16 16">
								<path d="M10 1H15V6" />
								<path d="M15 1L8 8" />
								<path d="M12 9.5V15H1V4H6.5" />
							</svg>
						</PurpleLink>
						. Previous to that I was at Ableton where I helped make educational apps and websites like{" "}
						<GreenLink href="https://learningsynths.ableton.com" target="_blank">
							Learning Synths
							<svg width="16" height="16" viewBox="0 0 16 16">
								<path d="M10 1H15V6" />
								<path d="M15 1L8 8" />
								<path d="M12 9.5V15H1V4H6.5" />
							</svg>
						</GreenLink>
						.
					</p>
				</Intro>
				<Warning>
					<p>
						My Tome case study is coming soon. In the meantime, check out my{" "}
						<PurpleLink href="https://tome-prototypes.jmrhoades.com" target="_blank">
							Tome Prototypes
						</PurpleLink>{" "}
						site. There’s 3 years of working, React-based layout editor iterations in there.{" "}
						<PurpleLink href="https://tome-prototypes.jmrhoades.com/layout-07/" target="_blank">
							Layoutv3 07
						</PurpleLink>{" "}
						is a good example.
					</p>
				</Warning>
				<OldStuff>
					<p>
						And here are two case studies of past work for your review. They’re a bit out date but I hope they give
						you a sense of my capabilities as a designer and engineer.
					</p>
				</OldStuff>
				<Project>
					<Link to="/case-study/learning-synths">
						<Description color="#00D692">
							<h2>Learning Synths</h2>
							<p>The fundamentals of sound design with synthesis, right in your browser.</p>
							<ViewLink color="#00D692" shade="#003121">
								View Project{" "}
								<svg width="24" height="16" viewBox="0 0 24 16">
									<path d="M6 8L22 8" />
									<path d="M14 1L22 8L14 15" />
								</svg>
							</ViewLink>
						</Description>
						<Figure>
							<FigureLearningSynths />
						</Figure>
					</Link>
				</Project>
				<Projects>
					<Project>
						<Link to="/case-study/mobile-beatmaking">
							<Description color="#00AAFF">
								<h2>Mobile Beatmaking</h2>
								<p>Interaction studies for bringing concepts from Ableton Live to iOS.</p>
								<ViewLink color="#00AAFF" shade="#052A33">
									View Project{" "}
									<svg width="24" height="16" viewBox="0 0 24 16">
										<path d="M6 8L22 8" />
										<path d="M14 1L22 8L14 15" />
									</svg>
								</ViewLink>
							</Description>
							<Figure>
								<FigureAbletonApp />
							</Figure>
						</Link>
					</Project>
				</Projects>

				<Outro>
					<p>Thanks for taking a look.</p>
					<svg viewBox="0 0 127 85">
						<path d="M1.87939 32.9282C6.11063 32.2585 10.828 34.2271 15.0274 33.402" />
						<path d="M20.4013 39.6258C30.5505 35.4076 40.4367 30.5377 49.8226 24.8178C58.1059 19.7699 70.5916 13.8554 73.9045 3.88052C74.0312 3.499 74.5905 -0.621252 73.7411 1.71086C69.9925 12.0017 67.0311 22.1873 62.027 32.0319C54.9754 45.9047 47.3533 59.7604 39.0348 72.9301C36.8746 76.3501 32.5564 84.2166 27.4842 83.9954C21.7385 83.7449 17.5411 78.437 15.7702 73.4726C13.3331 66.6408 17.0527 59.1396 21.7089 54.2168C28.7685 46.7529 37.5317 41.7359 47.1529 38.324C53.7888 35.9707 60.1436 34.321 66.1133 30.4047C68.546 28.8088 70.1898 27.2768 70.4175 24.3296C70.4836 23.4749 69.6403 25.8602 69.2189 26.6078C66.8658 30.7819 64.3032 35.9194 63.6615 40.7106C63.2812 43.55 64.0922 43.6213 66.3312 42.3379C70.0506 40.2059 73.1774 37.3378 75.8114 33.9846C76.918 32.576 77.7767 30.4009 78.9988 29.1843C80.7577 27.4331 74.3496 36.585 76.8194 36.2899C81.2253 35.7636 86.2797 31.3664 89.5686 28.7232C91.5557 27.1263 93.8113 25.2879 94.8808 22.8922C97.1149 17.8879 92.4776 34.2075 88.5879 38.0799C87.2659 39.396 86.9682 38.8945 86.8989 37.2391C86.8293 35.5746 91.1987 34.0572 92.3201 33.0354C95.9638 29.7153 99.1387 25.3328 101.446 20.9938C102.276 19.4336 103.489 17.9796 101.8 20.4242C99.2651 24.0954 96.0602 27.8927 95.2895 32.4116C94.6115 36.3864 99.7458 36.4717 102.59 36.2357C110.119 35.611 117.042 32.4196 124.711 32.6828C125.016 32.6933 125.349 37.1387 125.583 37.8358C126.715 41.2174 125.285 38.5717 124.357 37.1849" />
						<path d="M87 22C97.0092 21.8246 107.065 21.1883 117 20" />
					</svg>
					<ul>
						<li>
							<a href="./justin_rhoades_cv_2024.pdf">CV / Résumé (PDF)</a>
						</li>
						<li>
							<a href="mailto:justin.rhoades@gmail.com">justin.rhoades@gmail.com</a>
						</li>
					</ul>
				</Outro>
			</Container>

			<AnimatePresence>
				<Switch location={location} key={location.pathname}>
					<Route exact path="/case-study/mobile-beatmaking">
						<Page pKey="case-study-mobile-beatmaking" background={Colors.z1} foreground={Colors.t9}>
							<CaseStudy key="mobile-beatmaking" id="mobile-beatmaking" />
						</Page>
					</Route>
					<Route exact path="/case-study/learning-synths">
						<Page pKey="case-study-learning-synths" background={Colors.z1} foreground={Colors.t9}>
							<CaseStudy key="learning-synths" id="learning-synths" />
						</Page>
					</Route>
				</Switch>
			</AnimatePresence>

			<AnimatePresence>{tagsOpen && <TagsOverlay setTagsOpen={setTagsOpen} />}</AnimatePresence>
		</Wrap>
	);
};

const Wrap = styled.div`
	padding-top: 8em;
	padding-left: 1em;
	padding-right: 1em;
	padding-bottom: 4em;

	@media (min-width: 480px) {
		padding-left: 2em;
		padding-right: 2em;
	}
`;
const Container = styled.div`
	max-width: 40ch;
	margin: 0 auto;
	position: relative;
`;
const Intro = styled.div`
	min-height: 100vh;
	h1 {
		padding-bottom: 1em;
		margin-bottom: 0;
	}
`;
const Warning = styled.div`
	min-height: 100vh;
	/* padding-bottom: 3em; */
`;
const OldStuff = styled.div`
	
	padding-bottom: 2em;
`;
const YellowLink = styled(motion.a)`
	color: #ffc25e;
	text-decoration: none;
	position: relative;
	&:hover {
		text-decoration: none;
	}
`;
const GreenLink = styled.a`
	color: #00d692;
	text-decoration: none;
	position: relative;
	display: inline-block;
	text-decoration: underline;
	/* padding-right: 20px; */
	&:hover {
		text-decoration: underline;
	}
	svg {
		display: none;
		position: absolute;
		top: 5px;
		right: 0;
		fill: none;
		stroke: #00d692;
		stroke-width: 2px;
	}
`;

const PurpleLink = styled.a`
	color: #c789ed;
	text-decoration: none;
	position: relative;
	display: inline-block;
	text-decoration: underline;
	/* padding-right: 20px; */
	&:hover {
		text-decoration: underline;
	}
	svg {
		display: none;
		position: absolute;
		top: 5px;
		right: 0;
		fill: none;
		stroke: rgb(240, 77, 255);
		stroke-width: 2px;
	}
`;

const MagentaLink = styled.a`
	color: rgba(237, 0, 235, 1);
	text-decoration: none;
	position: relative;
	display: inline-block;
	text-decoration: underline;
	/* padding-right: 20px; */
	&:hover {
		text-decoration: underline;
	}
	svg {
		display: none;
		position: absolute;
		top: 5px;
		right: 0;
		fill: none;
		stroke: rgb(240, 77, 255);
		stroke-width: 2px;
	}
`;

const Projects = styled.ul`
	margin-bottom: 6em;
`;

const Project = styled.li`
	margin-bottom: 4em;

	a {
		display: block;
		@media (min-width: 580px) {
			display: flex;
			justify-content: space-between;
		}

		text-decoration: none;
		color: #bbbbbb;
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			background-color: #1a1a1a;
			left: -40px;
			right: -40px;
			top: -40px;
			bottom: -40px;
			border-radius: 23px;
			opacity: 0;
			transform: scale(0.95);
			transition: all 0.2s ease-in-out;
			transition-property: opacity, transform;
		}
		&:hover {
			h2 {
				text-decoration: underline;
			}
			&:before {
				opacity: 1;
				transform: scale(1);
			}
		}
		&:active {
			&:before {
				background-color: #1f1f1f;
			}
		}
		&:hover:active {
			h2 {
				text-decoration: none;
			}
		}
	}
`;

const Description = styled.div`
	padding-right: 1em;
	margin: auto;
	position: relative;
	p {
		margin: 0;
	}
	> * {
		font-size: 80%;
	}
`;
const Figure = styled.div`
	position: relative;
	transform: scale(0.75);
	transform-origin: 0 0;
	margin-top: 1em;

	@media (min-width: 480px) {
		transform: scale(1);
	}
`;
const ViewLink = styled.div`
	display: inline-block;
	color: ${props => props.color};
	position: relative;
	margin-top: 0.5em;
	svg {
		fill: none;
		stroke: ${props => props.color};
		stroke-width: 3px;
		display: block;
		position: absolute;
		top: calc(50% - 8px);
		right: -30px;
	}
`;

const Outro = styled.div`
	padding-top: 4em;
	font-size: 0.75em;
	p {
		max-width: 75%;
	}
	a {
		text-decoration: none;
		color: #ffc25e;
		&:hover {
			text-decoration: underline;
		}
	}
	svg {
		width: 100px;
		margin-bottom: 1em;
		stroke: #bbbbbb;
		stroke-width: 1.25px;
		stroke-linecap: round;
		stroke-linejoin: round;
	}
	li {
		margin-bottom: 0.75em;
	}
`;
